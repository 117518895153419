import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import NewUIListingCard from "../components/Card/newuilistingcard"
import SEOHeader from "../components/seo-header"
import { graphql, Link, navigate } from "gatsby"
import BlogCardCategory from "../components/blog/blog-card-category"
import { CoworkingContentLocality } from "../components/pagecontent/coworkingcontent"
const CoworkingSpacesLocality = props => {
  const { city } = props.pageContext
  const { cityslug } = props.pageContext
  const { locality } = props.pageContext
  const { slug } = props.pageContext

  const [spaceFilter, setspaceFilter] = useState("All")
  const [spaceSize, setspaceSize] = useState([])
  const [instantBooking, setInstantBooking] = useState("false")
  const [privateCabinFilter, setprivateCabinFilter] = useState("false")
  const [openDeskFilter, setopenDeskFilter] = useState("false")
  const [MetroNearByFilter, setMetroNearByFilter] = useState("false")
  const [DailyPassFilter, setDailyPassFilter] = useState("false")
  const [TwentyFourFilter, setTwentyFourFilter] = useState("false")
  const [filterStyle, setfilterStyle] = useState("Popular")
  const [mobileFilterSecondary, setmobileFilterSecondary] = useState(false)
  const [mobileFilter, setmobileFilter] = useState(false)
  const filter = {
    category: "Coworking",
    keyword: city,
  }
  function privateCabinCheck() {
    if (privateCabinFilter === "true") {
      setprivateCabinFilter("false")
    } else {
      setprivateCabinFilter("true")
      setspaceFilter("Private Cabin")
    }
  }
  function openDeskCheck() {
    if (openDeskFilter === "true") {
      setopenDeskFilter("false")
    } else {
      setopenDeskFilter("true")
      setspaceFilter("Open Desk")
    }
  }
  function MetroNearByCheck() {
    if (MetroNearByFilter === "true") {
      setMetroNearByFilter("false")
    } else {
      setMetroNearByFilter("true")
      setspaceFilter("Metro Nearby")
    }
  }
  function InstantBookingCheck() {
    if (instantBooking === "true") {
      setInstantBooking("false")
    } else {
      setInstantBooking("true")
      setspaceFilter("Instant Booking")
    }
  }
  function DailyPassCheck() {
    if (DailyPassFilter === "true") {
      setDailyPassFilter("false")
    } else {
      setDailyPassFilter("true")
      setspaceFilter("Daily Pass")
    }
  }
  function TwentyFourCheck() {
    if (TwentyFourFilter === "true") {
      setTwentyFourFilter("false")
    } else {
      setTwentyFourFilter("true")
      setspaceFilter("Twenty Four Hours")
    }
  }
  var officequery,
    metroquery,
    opendeskquery,
    privatecabinquery,
    dailypassquery,
    instantbookingquery,
    twentyfourquery = " "
  if (openDeskFilter === "true") {
    opendeskquery = "Open Desk"
  } else {
    opendeskquery = " "
  }
  if (privateCabinFilter === "true") {
    privatecabinquery = "Private Cabin"
  } else {
    privatecabinquery = " "
  }
  if (DailyPassFilter === "true") {
    dailypassquery = "Daily Pass"
  } else {
    dailypassquery = " "
  }
  if (instantBooking === "true") {
    instantbookingquery = "Instant Booking"
  } else {
    instantbookingquery = " "
  }
  if (MetroNearByFilter === "true") {
    metroquery = "Metro Nearby"
  } else {
    metroquery = " "
  }
  if (TwentyFourFilter === "true") {
    twentyfourquery = "Twenty Four Hours"
  } else {
    twentyfourquery = " "
  }
  if (
    privateCabinFilter === "false" &&
    DailyPassFilter === "false" &&
    MetroNearByFilter === "false" &&
    TwentyFourFilter === "false"
  ) {
    officequery = true
  }

  const OfficeFilter = () => {
    useEffect(() => {
      setspaceSize(document.querySelectorAll(".listingCardPaddingNew").length)
    })
    return (
      <div className="officefiltercontainer">
        <ul className="OfficeFilter scrollmenu">
          <li>
            <a
              className={"CheckBox " + instantBooking}
              onClick={InstantBookingCheck}
            >
              Instantly Book{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + MetroNearByFilter}
              onClick={MetroNearByCheck}
            >
              Near Metro{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + privateCabinFilter}
              onClick={privateCabinCheck}
            >
              Private Cabin{" "}
              <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a className={"CheckBox " + openDeskFilter} onClick={openDeskCheck}>
              Desk <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              className={"CheckBox " + TwentyFourFilter}
              onClick={TwentyFourCheck}
            >
              24x7 <i className="fa fa-times-circle" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
    )
  }
  const lists = props.data.allListings.edges
  const LocalityList = props.data.allLocalities.edges
  const listscount = props.data.city.edges

  useEffect(() => {
    if (lists.length <= 0) {
      navigate("/coworking-spaces/" + cityslug + "/all")
    }
  }, [])
  const changeFilter = filterText => {
    setmobileFilterSecondary(false)
    setfilterStyle(filterText)
    if (filterText === "PriceLow") {
      lists.sort(function (a, b) {
        if (Number(a.node.priceHr) < Number(b.node.priceHr)) {
          return -1
        }
      })
      lists.sort(function (a, b) {
        if (Number(a.node.priceDay) < Number(b.node.priceDay)) {
          return -1
        }
      })
    }
    if (filterText === "PriceHigh") {
      lists.sort(function (a, b) {
        if (Number(a.node.priceDay) > Number(b.node.priceDay)) {
          return -1
        }
      })
      lists.sort(function (a, b) {
        if (Number(a.node.priceHr) > Number(b.node.priceHr)) {
          return -1
        }
      })
    }
    if (filterText === "User Rating") {
      lists.sort(function (a, b) {
        if (Number(a.node.Rating) > Number(b.node.Rating)) {
          return -1
        }
      })
    }
    if (filterText === "Nearest") {
      lists.sort(function (a, b) {
        if (Number(a.node.pincode) > Number(b.node.pincode)) {
          return -1
        }
      })
    }
    if (filterText === "Popular") {
      lists.sort(function (a, b) {
        if (Number(a.node.bookingcount) > Number(b.node.bookingcount)) {
          return -1
        }
      })
    }
  }
  lists.sort(function (a, b) {
    if (Number(a.node.online) > Number(b.node.online)) {
      return -1
    }
  })
  return (
    <div>
      <SEOHeader
        title={
          "Find the Best Coworking Spaces in " +
          locality +
          ", " +
          city +
          " | GoFloaters"
        }
        description={
          "Find the best coworking spaces in " +
          locality +
          ", " +
          city +
          " from GoFloaters and get access to fully equipped, safe and verified spaces and work near home instantly."
        }
        socialURL={
          "https://assets.gofloaters.com/socialimage/coworking-spaces-in-" +
          cityslug +
          ".jpg"
        }
        pinterest="true"
      ></SEOHeader>

      <Layout>
        <div
          style={{
            position: "fixed",
            bottom: mobileFilterSecondary ? "0px" : "-100px",
            zIndex: "10",
            width: "100%",
            opacity: mobileFilterSecondary ? "1" : "0",
            background: "#fdfdfd",
            transition: "0.5s",
            overflow: "scroll",
            overflowX: "hidden",
            boxShadow: "0px -16px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{ padding: "5px 15px", borderBottom: "1px solid #d6d6d6" }}
          >
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>Sort</p>
          </div>

          <div className="col-md-12">
            <div
              className={
                filterStyle === "Popular" ? "sortingCard active" : "sortingCard"
              }
              onClick={() => {
                changeFilter("Popular")
              }}
            >
              <p>
                <b>Popular:</b> Frequently booked
              </p>
            </div>
          </div>

          <div className="col-md-12">
            <div
              className={
                filterStyle === "User Rating"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("User Rating")
              }}
            >
              <p>
                <b>User Rating:</b> Highest first
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={
                filterStyle === "PriceLow"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceLow")
              }}
            >
              <p>
                <b>Price:</b> Lowest first
              </p>
            </div>
          </div>
          <div className="col-md-2">
            <div
              className={
                filterStyle === "PriceHigh"
                  ? "sortingCard active"
                  : "sortingCard"
              }
              onClick={() => {
                changeFilter("PriceHigh")
              }}
            >
              <p>
                <b>Price:</b> Highest first
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <br></br>
          <h1>
            Best Coworking spaces in {locality}, {city}{" "}
          </h1>
          <p style={{ fontSize: "13px" }}>
            If you are looking for the best coworking spaces in {locality},{" "}
            {city} then you have come to the right page. An average cost of a
            hot desk in {locality}, {city} is in the range of Rs 250 / day.{" "}
          </p>
          <OfficeFilter></OfficeFilter>
          <br></br>
          <div class="popularscrollmenu-s">
            <Link
              to={"/coworking-spaces/" + cityslug + "/all"}
              className="popularlocalityLink"
            >
              All
            </Link>
            {LocalityList.map(local => {
              const localitynew = listscount.filter(
                list =>
                  list.node.spaceAddress === local.node.localityName &&
                  list.node.dayPassAvailable === true
              )
              if (localitynew.length > 0) {
                return (
                  <Link
                    to={
                      "/coworking-spaces/" +
                      cityslug +
                      "/" +
                      local.node.localitySlug
                    }
                    className={
                      local.node.localityName === locality
                        ? "popularlocalityLink active centerAligner "
                        : "popularlocalityLink centerAligner"
                    }
                  >
                    {local.node.localityName}
                    {"  "}
                    <div className="countSlip centerAligner">
                      {localitynew.length}
                    </div>
                  </Link>
                )
              }
            })}
          </div>
          <div className="DesktopOnly">
            <div className="row alignersorting sortingCardList">
              <div className="col-md-2">
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    color: "#2193b0",
                  }}
                >
                  SORT BY:
                </p>
              </div>
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "Popular"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("Popular")
                  }}
                >
                  <p>Popular</p>
                  <span>Frequently booked</span>
                </div>
              </div>
              {/* 
                    <div className="col-md-2">
                      <div
                        className={
                          filterStyle === "Nearest"
                            ? "sortingCard active"
                            : "sortingCard"
                        }
                        onClick={() => {
                          changeFilter("Nearest")
                        }}
                      >
                        <p>Nearest</p>
                        <span>Closest to you</span>
                      </div>
                    </div> */}
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "User Rating"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("User Rating")
                  }}
                >
                  <p>User Rating</p>
                  <span>Highest first</span>
                </div>
              </div>
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "PriceLow"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("PriceLow")
                  }}
                >
                  <p>Price</p>
                  <span>Lowest first</span>
                </div>
              </div>
              <div className="col-md-2">
                <div
                  className={
                    filterStyle === "PriceHigh"
                      ? "sortingCard active"
                      : "sortingCard"
                  }
                  onClick={() => {
                    changeFilter("PriceHigh")
                  }}
                >
                  <p>Price</p>
                  <span>Highest first</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row alignersorting">
            <div className="MobileOnly ">
              <br />
              <span
                onClick={() => {
                  setmobileFilterSecondary(!mobileFilterSecondary)
                }}
                className="sortNowButton"
              >
                <i
                  className="fa fa-sort-amount-asc"
                  style={{ paddingRight: "10px", cursor: "pointer" }}
                ></i>{" "}
                Sort
              </span>
            </div>
          </div>
          <div className="col-md-12 col-12">
            <p style={{ padding: "15px 0 0", color: "#7a7a7a" }}>
              {spaceSize} Spaces match your filter{" "}
            </p>
          </div>
          <div className="row">
            {lists.map((list, i) => {
              const listData = list.node
              if (
                listData.dayPassAvailable === true &&
                listData.Facility.search(opendeskquery) > 1 &&
                listData.Facility.search(privatecabinquery) > 1 &&
                listData.Facility.search(dailypassquery) > 1 &&
                listData.Facility.search(metroquery) > 1 &&
                listData.Facility.search(twentyfourquery) > 1 &&
                listData.Facility.search(instantbookingquery) > 1
              ) {
                return (
                  <NewUIListingCard
                    key={listData.spaceId}
                    spacetype={listData.spaceType}
                    listingImg={listData.spaceImage}
                    title={listData.spaceTitle}
                    gftitle={listData.spaceGFName}
                    spaceDesc={listData.spaceDesc}
                    spaceAddress={listData.spaceAddress}
                    priceHr={listData.priceHr}
                    priceDay={listData.priceDay}
                    priceMonth={listData.priceMonth}
                    monthPassAvailable={listData.monthPassAvailable}
                    dayPassAvailable={listData.dayPassAvailable}
                    hourPassAvailable={listData.hourPassAvailable}
                    spaceId={listData.spaceId}
                    officeSpaceType={listData.officeSpaceType}
                    spaceDisplayName={listData.spaceDisplayName}
                    OriginalName={listData.OriginalName}
                    Facility={listData.Facility}
                    Slug={"/coworking-space/" + listData.slug}
                    hasCovidSafeBadge={listData.hasCovidSafeBadge}
                    online={listData.online}
                    Rating={listData.Rating}
                    needsBookingConfirmation={listData.needsBookingConfirmation}
                    type="Coworking"
                  ></NewUIListingCard>
                )
              }
            })}
          </div>
          <br></br>
          <CoworkingContentLocality city={city} locality={locality} />
        </div>{" "}
        <br></br>
        <br></br>{" "}
        <div className="container">
          <div className="row">
            <BlogCardCategory filter={filter} />
          </div>
        </div>
        <br></br>
        <br></br>{" "}
      </Layout>
    </div>
  )
}
export default CoworkingSpacesLocality
export const query = graphql`
  query CoworkingSpacesLocality($city: String!, $locality: String!) {
    city: allListings(
      filter: {
        spaceType: { eq: "Shared Workspace" }
        spaceCity: { eq: $city }
        dayPassAvailable: { eq: true }
        slug: { ne: "" }
      }
      sort: { fields: bookingcount, order: DESC }
    ) {
      totalCount
      edges {
        node {
          OriginalName
          spaceAddress
          dayPassAvailable
        }
      }
    }
    allListings(
      filter: {
        subType: { eq: "Office Space" }
        spaceCity: { eq: $city }
        dayPassAvailable: { eq: true }
        spaceAddress: { eq: $locality }
      }
      sort: { fields: bookingcount, order: DESC }
    ) {
      totalCount
      edges {
        node {
          monthPassAvailable
          dayPassAvailable
          hourPassAvailable
          officeSpaceType
          purposesList
          spaceAddress
          spaceGFName
          OriginalName
          spaceCity
          spaceDesc
          spaceId
          spaceImage
          spaceTitle
          spaceType
          subType
          priceDay
          priceHr
          priceMonth
          spaceDisplayName
          Facility
          slug
          hasCovidSafeBadge
          online
          Rating
          needsBookingConfirmation
        }
      }
    }

    allLocalities(
      filter: { cityName: { eq: $city } }
      sort: { fields: localityName }
    ) {
      edges {
        node {
          cityName
          cityPriority
          cityslug
          displayCity
          id
          localitySort
          localitySlug
          localityName
        }
      }
    }
  }
`
